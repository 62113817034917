import { useRouter } from 'next/router'
import { useMemo } from 'react'

export function useParams() {
  const router = useRouter()
  return router.query
}

export function useNavigate() {
  const router = useRouter()
  return router.push
}

export function useMatch(pathnameMatch, exact = false) {
  const { pathname } = useRouter()

  return useMemo(() => {
    return isMatch(pathname, pathnameMatch, exact)
  }, [exact, pathname, pathnameMatch])
}

export function isMatch(pathname, pathnameMatch, exact = false) {
  if (exact) {
    return pathnameMatch === pathname
  } else {
    return pathname.indexOf(pathnameMatch) !== -1
  }
}
