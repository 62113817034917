export default function createClassName(
  classNames = [],
  removeClassNames = []
) {
  const classes = new Set(
    classNames
      .filter(Boolean)
      .map((str) => str?.split(' '))
      .flat()
      .filter(Boolean)
  )
  removeClassNames
    .filter(Boolean)
    .map((str) => str.split(' '))
    .flat()
    .forEach((className) => {
      classes.delete(className)
    })

  return Array.from(classes).join(' ')
}
