import React from 'react'
import { InlineButton } from '@ralston-instruments/app.fieldlab.components.web'
import { signOut } from 'next-auth/react'
import { useSession } from '@/util/session/index'
import Link from 'next/link'
import { UserCircleIcon } from '@heroicons/react/24/solid'

function LinkComponent({ className, children }) {
  return (
    <Link href="/profile" className={className}>
      {children}
    </Link>
  )
}

const User = ({ linkToProfile = true }) => {
  const { data: session } = useSession()
  const { user } = session
  const Wrapper = linkToProfile ? LinkComponent : 'div'
  return (
    <div className="flex-shrink-0 w-full flex items-center gap-3">
      <Wrapper className="flex-1 group flex items-center gap-3">
        <div>
          <UserCircleIcon className="w-9 h-9" />
        </div>
        <div className="flex-1">
          <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
            {user.first_name} {user.last_name}
          </p>
          <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
            View profile
          </p>
        </div>
      </Wrapper>
      <div>
        <InlineButton
          onClick={() => signOut()}
          componentState="link"
          text="Sign Out"
          size="xs"
        />
      </div>
    </div>
  )
}

export default User
