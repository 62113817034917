// paths to our own api routes (not fieldlab api routes)

const apiPaths = {
  userInfo: () => `/api/userinfo`,
  userSignature: () => `/api/userinfo/signature`,
  userSignatureAsset: () => `/api/userinfo/signature/asset`,
  userSignatureAssetDownload: ({ filename }) =>
    `/api/userinfo/signature/${filename}.png`,

  calibrationReportRoleTypes: () =>
    `/api/calibration-report-management/role-types`,

  testReportStatusTypes: () => `/api/test-report-management/status-types`,
  testReportTypes: () => `/api/test-report-management/report-types`,

  serviceLabs: () => `/api/service-labs`,

  serviceLab: ({ serviceLabId } = {}) =>
    serviceLabId ? `/api/service-labs/${serviceLabId}` : null,

  serviceLabShippingAddress: ({ serviceLabId } = {}) =>
    serviceLabId ? `/api/service-labs/${serviceLabId}/shipping-address` : null,

  serviceLabDevices: ({ serviceLabId } = {}) =>
    serviceLabId ? `/api/service-labs/${serviceLabId}/devices` : null,

  serviceLabDevicesAutocomplete: ({ serviceLabId } = {}) =>
    serviceLabId
      ? `/api/service-labs/${serviceLabId}/devices/autocomplete`
      : null,

  serviceLabDevice: ({ serviceLabId, deviceId } = {}) =>
    serviceLabId && deviceId
      ? `/api/service-labs/${serviceLabId}/devices/${deviceId}`
      : null,

  serviceLabDeviceTestReports: ({ serviceLabId, deviceId } = {}) =>
    serviceLabId && deviceId
      ? `/api/service-labs/${serviceLabId}/devices/${deviceId}/test-reports`
      : null,

  serviceLabDataSetManagementDataSet: ({ serviceLabId, dataSetId } = {}) =>
    serviceLabId && dataSetId
      ? `/api/service-labs/${serviceLabId}/data-set-management/data-sets/${dataSetId}`
      : null,

  serviceLabDataSetManagementDataSetData: ({ serviceLabId, dataSetId } = {}) =>
    serviceLabId && dataSetId
      ? `/api/service-labs/${serviceLabId}/data-set-management/data-sets/${dataSetId}/data`
      : null,

  serviceLabDeviceCertificates: ({ serviceLabId } = {}) =>
    serviceLabId ? `/api/service-labs/${serviceLabId}/certificates` : null,

  serviceLabDeviceCertificate: ({ serviceLabId, certId } = {}) =>
    serviceLabId && certId
      ? `/api/service-labs/${serviceLabId}/certificates/${certId}`
      : null,

  serviceLabDeviceCertificateAsset: ({ serviceLabId, certId } = {}) =>
    `/api/service-labs/${serviceLabId}/certificates/${certId}/asset`,
  serviceLabDeviceCertificateDownload: ({
    serviceLabId,
    certId,
    fileName,
  } = {}) =>
    serviceLabId && certId && fileName
      ? `/api/service-labs/${serviceLabId}/certificates/${certId}/${fileName}`
      : null,

  serviceLabDeviceHistory: ({ serviceLabId, deviceId } = {}) =>
    serviceLabId && deviceId
      ? `/api/service-labs/${serviceLabId}/devices/${deviceId}/history`
      : null,

  serviceLabDeviceHistoryItem: ({
    serviceLabId,
    deviceId,
    historyItemId,
  } = {}) =>
    serviceLabId && deviceId && historyItemId
      ? `/api/service-labs/${serviceLabId}/devices/${deviceId}/history/${historyItemId}`
      : null,

  serviceLabDeviceSensors: ({ serviceLabId, deviceId } = {}) =>
    serviceLabId && deviceId
      ? `/api/service-labs/${serviceLabId}/devices/${deviceId}/sensors`
      : null,

  serviceLabDeviceSensor: ({ serviceLabId, deviceId, sensorId } = {}) =>
    serviceLabId && deviceId && sensorId
      ? `/api/service-labs/${serviceLabId}/devices/${deviceId}/sensors/${sensorId}`
      : null,

  serviceLabUsers: ({ serviceLabId } = {}) =>
    serviceLabId ? `/api/service-labs/${serviceLabId}/users` : null,

  serviceLabUser: ({ serviceLabId, userId } = {}) =>
    serviceLabId ? `/api/service-labs/${serviceLabId}/users/${userId}` : null,

  serviceLabInvitations: ({ serviceLabId } = {}) =>
    serviceLabId ? `/api/service-labs/${serviceLabId}/invitations` : null,

  serviceLabInvitation: ({ serviceLabId, invitationId } = {}) =>
    serviceLabId && invitationId
      ? `/api/service-labs/${serviceLabId}/invitations/${invitationId}`
      : null,

  serviceLabInvitationSend: ({ serviceLabId, invitationId } = {}) =>
    serviceLabId && invitationId
      ? `/api/service-labs/${serviceLabId}/invitations/${invitationId}/send`
      : null,

  serviceLabInvitationAccept: ({ serviceLabId, invitationId } = {}) =>
    serviceLabId && invitationId
      ? `/api/service-labs/${serviceLabId}/invitations/${invitationId}/accept`
      : null,

  serviceLabTestReports: ({ serviceLabId } = {}) =>
    serviceLabId ? `/api/service-labs/${serviceLabId}/test-reports` : null,

  serviceLabTestReport: ({ serviceLabId, testReportId } = {}) =>
    serviceLabId && testReportId
      ? `/api/service-labs/${serviceLabId}/test-reports/${testReportId}`
      : null,

  serviceLabTestReportPublish: ({ serviceLabId, testReportId } = {}) =>
    serviceLabId && testReportId
      ? `/api/service-labs/${serviceLabId}/test-reports/${testReportId}/publish`
      : null,

  serviceLabTestReportTestDevice: ({ serviceLabId, testReportId } = {}) =>
    serviceLabId && testReportId
      ? `/api/service-labs/${serviceLabId}/test-reports/${testReportId}/test-device`
      : null,

  serviceLabTestReportEnvironmentConditions: ({
    serviceLabId,
    testReportId,
  } = {}) =>
    serviceLabId && testReportId
      ? `/api/service-labs/${serviceLabId}/test-reports/${testReportId}/environment-conditions`
      : null,

  serviceLabTestReportReferenceDevices: ({ serviceLabId, testReportId } = {}) =>
    serviceLabId && testReportId
      ? `/api/service-labs/${serviceLabId}/test-reports/${testReportId}/reference-devices`
      : null,

  serviceLabTestReportReferenceDevice: ({
    serviceLabId,
    testReportId,
    referenceId,
  } = {}) =>
    serviceLabId && testReportId && referenceId
      ? `/api/service-labs/${serviceLabId}/test-reports/${testReportId}/reference-devices/${referenceId}`
      : null,

  dataSetManagementDataSetAttachmentTemplates: ({ serviceLabId } = {}) =>
    serviceLabId
      ? `/api/service-labs/${serviceLabId}/data-set-management/data-set-attachment-templates`
      : null,

  dataSetManagementDataSetAttachmentTemplate: ({
    serviceLabId,
    templateId,
  } = {}) =>
    serviceLabId && templateId
      ? `/api/service-labs/${serviceLabId}/data-set-management/data-set-attachment-templates/${templateId}`
      : null,

  dataSetManagementDataSetAttachment: ({
    serviceLabId,
    dataSetAttachmentId,
  } = {}) =>
    serviceLabId && dataSetAttachmentId
      ? `/api/service-labs/${serviceLabId}/data-set-management/data-set-attachments/${dataSetAttachmentId}`
      : null,

  dataSetManagementDataSetAttachments: ({ serviceLabId } = {}) =>
    serviceLabId
      ? `/api/service-labs/${serviceLabId}/data-set-management/data-set-attachments`
      : null,

  dataSetManagementDataSetAttachmentPreview: ({
    serviceLabId,
    dataSetAttachmentId,
  } = {}) =>
    serviceLabId && dataSetAttachmentId
      ? `/api/service-labs/${serviceLabId}/data-set-management/data-set-attachments/${dataSetAttachmentId}/preview`
      : null,

  dataSetManagementDataSetAttachmentProcessors: ({ serviceLabId } = {}) =>
    serviceLabId
      ? `/api/service-labs/${serviceLabId}/data-set-management/data-set-attachment-processors`
      : null,

  dataSetManagementDataSetAttachmentProcessor: ({
    serviceLabId,
    processorId,
  } = {}) =>
    serviceLabId && processorId
      ? `/api/service-labs/${serviceLabId}/data-set-management/data-set-attachment-processors/${processorId}`
      : null,

  dataSetManagementDataSetAttachmentProcessorProcess: ({
    serviceLabId,
    processorId,
  } = {}) =>
    serviceLabId && processorId
      ? `/api/service-labs/${serviceLabId}/data-set-management/data-set-attachment-processors/${processorId}/process`
      : null,

  dataSetManagementDataSetAttachmentProcessorPublish: ({
    serviceLabId,
    processorId,
  } = {}) =>
    serviceLabId && processorId
      ? `/api/service-labs/${serviceLabId}/data-set-management/data-set-attachment-processors/${processorId}/publish`
      : null,

  serviceLabTestReportDataSet: ({ serviceLabId, testReportId } = {}) =>
    serviceLabId && testReportId
      ? `/api/service-labs/${serviceLabId}/test-reports/${testReportId}/data-set`
      : null,

  serviceLabTestReportDataSetData: ({ serviceLabId, testReportId } = {}) =>
    serviceLabId && testReportId
      ? `/api/service-labs/${serviceLabId}/test-reports/${testReportId}/data-set/data`
      : null,

  serviceLabTestReportDataSetDataTable: ({ serviceLabId, testReportId } = {}) =>
    serviceLabId && testReportId
      ? `/api/service-labs/${serviceLabId}/test-reports/${testReportId}/data-set/data/table`
      : null,

  serviceLabTestReportDataSetDataDatum: ({
    serviceLabId,
    testReportId,
    rowId,
  } = {}) =>
    serviceLabId && testReportId && rowId
      ? `/api/service-labs/${serviceLabId}/test-reports/${testReportId}/data-set/data/${rowId}`
      : null,

  serviceLabCalibrationReports: ({ serviceLabId } = {}) =>
    serviceLabId
      ? `/api/service-labs/${serviceLabId}/calibration-reports`
      : null,

  serviceLabCalibrationReportsDraft: ({ serviceLabId } = {}) =>
    serviceLabId
      ? `/api/service-labs/${serviceLabId}/calibration-reports/draft`
      : null,

  serviceLabCalibrationReportsPending: ({ serviceLabId } = {}) =>
    serviceLabId
      ? `/api/service-labs/${serviceLabId}/calibration-reports/pending`
      : null,

  serviceLabCalibrationReportsApproved: ({ serviceLabId } = {}) =>
    serviceLabId
      ? `/api/service-labs/${serviceLabId}/calibration-reports/approved`
      : null,

  serviceLabCalibrationReportsSearch: ({ serviceLabId } = {}) =>
    serviceLabId
      ? `/api/service-labs/${serviceLabId}/calibration-reports/search`
      : null,

  serviceLabCalibrationReport: ({ serviceLabId, calibrationReportId } = {}) =>
    serviceLabId && calibrationReportId
      ? `/api/service-labs/${serviceLabId}/calibration-reports/${calibrationReportId}`
      : null,

  serviceLabCalibrationReportTransitionApprove: ({
    serviceLabId,
    calibrationReportId,
  } = {}) =>
    serviceLabId && calibrationReportId
      ? `/api/service-labs/${serviceLabId}/calibration-reports/${calibrationReportId}/transition/approve`
      : null,

  serviceLabCalibrationReportTransitionPending: ({
    serviceLabId,
    calibrationReportId,
  } = {}) =>
    serviceLabId && calibrationReportId
      ? `/api/service-labs/${serviceLabId}/calibration-reports/${calibrationReportId}/transition/pending`
      : null,

  serviceLabCalibrationReportTransitionDraft: ({
    serviceLabId,
    calibrationReportId,
  } = {}) =>
    serviceLabId && calibrationReportId
      ? `/api/service-labs/${serviceLabId}/calibration-reports/${calibrationReportId}/transition/draft`
      : null,

  serviceLabCalibrationReportApprove: ({
    serviceLabId,
    calibrationReportId,
  } = {}) =>
    serviceLabId && calibrationReportId
      ? `/api/service-labs/${serviceLabId}/calibration-reports/${calibrationReportId}/approve`
      : null,

  serviceLabCalibrationReportRevisionAmendment: ({
    serviceLabId,
    calibrationReportId,
  } = {}) =>
    serviceLabId && calibrationReportId
      ? `/api/service-labs/${serviceLabId}/calibration-reports/${calibrationReportId}/revision-amendment`
      : null,

  serviceLabCalibrationReportAmendments: ({
    serviceLabId,
    calibrationReportId,
  } = {}) =>
    serviceLabId && calibrationReportId
      ? `/api/service-labs/${serviceLabId}/calibration-reports/${calibrationReportId}/amendments`
      : null,

  serviceLabCalibrationReportAmendment: ({
    serviceLabId,
    calibrationReportId,
    amendmentId,
  } = {}) =>
    serviceLabId && calibrationReportId && amendmentId
      ? `/api/service-labs/${serviceLabId}/calibration-reports/${calibrationReportId}/amendments/${amendmentId}`
      : null,

  serviceLabCalibrationReportReviewers: ({
    serviceLabId,
    calibrationReportId,
  } = {}) =>
    serviceLabId && calibrationReportId
      ? `/api/service-labs/${serviceLabId}/calibration-reports/${calibrationReportId}/reviewers`
      : null,

  serviceLabCalibrationReportReviewerAsset: ({
    serviceLabId,
    calibrationReportId,
    reviewerId,
  } = {}) =>
    serviceLabId && calibrationReportId && reviewerId
      ? `/api/service-labs/${serviceLabId}/calibration-reports/${calibrationReportId}/reviewers/${reviewerId}/asset/signature.png`
      : null,

  serviceLabCalibrationReportTestReports: ({
    serviceLabId,
    calibrationReportId,
  } = {}) =>
    serviceLabId && calibrationReportId
      ? `/api/service-labs/${serviceLabId}/calibration-reports/${calibrationReportId}/test-reports`
      : null,

  serviceLabCalibrationReportTestReportsChangeOrder: ({
    serviceLabId,
    calibrationReportId,
  } = {}) =>
    serviceLabId && calibrationReportId
      ? `/api/service-labs/${serviceLabId}/calibration-reports/${calibrationReportId}/test-reports/change-order`
      : null,

  serviceLabCalibrationReportTestReport: ({
    serviceLabId,
    calibrationReportId,
    testReportId,
  } = {}) =>
    serviceLabId && calibrationReportId && testReportId
      ? `/api/service-labs/${serviceLabId}/calibration-reports/${calibrationReportId}/test-reports/${testReportId}`
      : null,

  serviceLabCalibrationReportCustomerAddress: ({
    serviceLabId,
    calibrationReportId,
  } = {}) =>
    serviceLabId && calibrationReportId
      ? `/api/service-labs/${serviceLabId}/calibration-reports/${calibrationReportId}/customer-address`
      : null,

  serviceLabCalibrationReportDownload: ({
    serviceLabId,
    calibrationReportId,
  } = {}) =>
    serviceLabId && calibrationReportId
      ? `/api/service-labs/${serviceLabId}/calibration-reports/${calibrationReportId}/download`
      : null,

  serviceLabReferenceDevices: ({ serviceLabId } = {}) =>
    serviceLabId
      ? `/api/service-labs/${serviceLabId}/device-management/reference-devices`
      : null,

  serviceLabReferenceDevicesDownload: ({ serviceLabId } = {}) =>
    serviceLabId
      ? `/api/service-labs/${serviceLabId}/device-management/reference-devices/download`
      : null,

  serviceLabReferenceDevice: ({ serviceLabId, referenceId } = {}) =>
    serviceLabId && referenceId
      ? `/api/service-labs/${serviceLabId}/device-management/reference-devices/${referenceId}`
      : null,

  serviceLabReferenceDeviceCertByDate: ({ serviceLabId, referenceId } = {}) =>
    serviceLabId && referenceId
      ? `/api/service-labs/${serviceLabId}/device-management/reference-devices/${referenceId}/certificate-by-date`
      : null,

  serviceLabDeviceManagementSearchReferences: ({ serviceLabId } = {}) =>
    serviceLabId
      ? `/api/service-labs/${serviceLabId}/device-management/devices/search-references`
      : null,

  serviceLabDeviceManagementSearchNonReferences: ({ serviceLabId } = {}) =>
    serviceLabId
      ? `/api/service-labs/${serviceLabId}/device-management/devices/search-non-references`
      : null,

  serviceLabDeviceManagementDevices: ({ serviceLabId } = {}) =>
    serviceLabId
      ? `/api/service-labs/${serviceLabId}/device-management/devices`
      : null,

  serviceLabDeviceManagementDevice: ({ serviceLabId, deviceId } = {}) =>
    serviceLabId && deviceId
      ? `/api/service-labs/${serviceLabId}/device-management/devices/${deviceId}`
      : null,

  serviceLabDeviceManagementProductTypes: ({ serviceLabId } = {}) =>
    serviceLabId
      ? `/api/service-labs/${serviceLabId}/device-management/product-types`
      : null,

  serviceLabDeviceManagementProductTypesSearch: ({ serviceLabId } = {}) =>
    serviceLabId
      ? `/api/service-labs/${serviceLabId}/device-management/product-types/search`
      : null,

  serviceLabDeviceManagementProductModels: ({ serviceLabId } = {}) =>
    serviceLabId
      ? `/api/service-labs/${serviceLabId}/device-management/product-models`
      : null,

  serviceLabDeviceManagementProductModelsSearch: ({ serviceLabId } = {}) =>
    serviceLabId
      ? `/api/service-labs/${serviceLabId}/device-management/product-models/search`
      : null,

  serviceLabDeviceManagementProducts: ({ serviceLabId } = {}) =>
    serviceLabId
      ? `/api/service-labs/${serviceLabId}/device-management/products`
      : null,

  serviceLabDeviceManagementProductsSearch: ({ serviceLabId } = {}) =>
    serviceLabId
      ? `/api/service-labs/${serviceLabId}/device-management/products/search`
      : null,

  serviceLabDeviceManagementManufacturers: ({ serviceLabId } = {}) =>
    serviceLabId
      ? `/api/service-labs/${serviceLabId}/device-management/manufacturers`
      : null,

  serviceLabDeviceManagementManufacturersSearch: ({ serviceLabId } = {}) =>
    serviceLabId
      ? `/api/service-labs/${serviceLabId}/device-management/manufacturers/search`
      : null,

  serviceLabCustomers: ({ serviceLabId } = {}) =>
    serviceLabId ? `/api/service-labs/${serviceLabId}/customers` : null,

  serviceLabCustomer: ({ serviceLabId, customerId } = {}) =>
    serviceLabId && customerId
      ? `/api/service-labs/${serviceLabId}/customers/${customerId}`
      : null,

  serviceLabCustomersSearch: ({ serviceLabId } = {}) =>
    serviceLabId ? `/api/service-labs/${serviceLabId}/customers/search` : null,

  serviceLabDeviceManagementModel: ({ serviceLabId, modelId } = {}) =>
    serviceLabId && modelId
      ? `/api/service-labs/${serviceLabId}/device-management/models/${modelId}`
      : null,

  adminProducts: () => `/api/admin/device-management/products`,

  adminProduct: ({ productId } = {}) =>
    productId ? `/api/admin/device-management/products/${productId}` : null,

  adminProductsSearch: () => `/api/admin/device-management/products/search`,

  adminProductModels: ({ productId } = {}) =>
    productId
      ? `/api/admin/device-management/products/${productId}/models`
      : null,

  adminManufacturers: () => `/api/admin/device-management/manufacturers`,

  adminManufacturersSearch: () =>
    `/api/admin/device-management/manufacturers/search`,

  adminManufacturer: ({ manufacturerId } = {}) =>
    manufacturerId
      ? `/api/admin/device-management/manufacturers/${manufacturerId}`
      : null,

  adminModels: () => `/api/admin/device-management/models`,

  adminModelsSearch: () => `/api/admin/device-management/models/search`,

  adminModel: ({ modelId } = {}) =>
    modelId ? `/api/admin/device-management/models/${modelId}` : null,

  adminModelSensors: ({ modelId } = {}) =>
    modelId ? `/api/admin/device-management/models/${modelId}/sensors` : null,

  adminModelSensor: ({ modelId, sensorId } = {}) =>
    modelId && sensorId
      ? `/api/admin/device-management/models/${modelId}/sensors/${sensorId}`
      : null,

  adminProductTypes: () => `/api/admin/device-management/product-types`,

  adminProductType: ({ productTypeId } = {}) =>
    productTypeId
      ? `/api/admin/device-management/product-types/${productTypeId}`
      : null,

  adminServiceLabs: () => `/api/admin/customer-management/service-labs`,

  adminServiceLab: ({ serviceLabId } = {}) =>
    serviceLabId
      ? `/api/admin/customer-management/service-labs/${serviceLabId}`
      : null,

  adminServiceLabUsers: ({ serviceLabId } = {}) =>
    serviceLabId
      ? `/api/admin/customer-management/service-labs/${serviceLabId}/service-lab-users`
      : null,

  adminServiceLabUser: ({ serviceLabId, userId } = {}) =>
    serviceLabId && userId
      ? `/api/admin/customer-management/service-labs/${serviceLabId}/service-lab-users/${userId}`
      : null,

  adminUsers: () => `/api/admin/customer-management/users`,

  adminUser: ({ userId } = {}) =>
    userId ? `/api/admin/customer-management/users/${userId}` : userId,

  adminAccounts: () => `/api/admin/customer-management/accounts`,

  adminAccount: ({ accountId } = {}) =>
    accountId ? `/api/admin/customer-management/accounts/${accountId}` : null,

  adminSensorTypes: () => `/api/admin/device-management/sensor-types`,

  adminSensorType: ({ sensorTypeId } = {}) =>
    sensorTypeId
      ? `/api/admin/device-management/sensor-types/${sensorTypeId}`
      : null,

  adminPressureTypes: () => `/api/admin/device-management/pressure-types`,

  adminPressureType: ({ pressureTypeId } = {}) =>
    pressureTypeId
      ? `/api/admin/device-management/pressure-types/${pressureTypeId}`
      : null,

  adminHumidityTypes: () => `/api/admin/device-management/humidity-types`,

  adminHumidityType: ({ humidityTypeId } = {}) =>
    humidityTypeId
      ? `/api/admin/device-management/humidity-types/${humidityTypeId}`
      : null,

  adminPressureUnitTypes: () =>
    `/api/admin/device-management/pressure-unit-types`,

  adminPressureUnitType: ({ pressureUnitTypeId } = {}) =>
    pressureUnitTypeId
      ? `/api/admin/device-management/pressure-unit-types/${pressureUnitTypeId}`
      : null,

  adminHumidityUnitTypes: () =>
    `/api/admin/device-management/humidity-unit-types`,

  adminHumidityUnitType: ({ humidityUnitTypeId } = {}) =>
    humidityUnitTypeId
      ? `/api/admin/device-management/humidity-unit-types/${humidityUnitTypeId}`
      : null,

  adminTemperatureUnitTypes: () =>
    `/api/admin/device-management/temperature-unit-types`,

  adminTemperatureUnitType: ({ temperatureUnitTypeId } = {}) =>
    temperatureUnitTypeId
      ? `/api/admin/device-management/temperature-unit-types/${temperatureUnitTypeId}`
      : null,
}

export default apiPaths
